import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('finance/type/listType', data)
}
//修改
export function getEdit(data) {
    return post('finance/type/editType', data)
}
//添加 
export function getAdd(data) {
    return post('finance/type/addType', data)
}
//删除
export function getDel(data) {
    return post('finance/type/delType', data)
}
//分类下拉
export function getOption(data) {
    return post('finance/type/selectType', data)
}
export function getState(data) {
    return post('finance/type/stateType', data)
}


